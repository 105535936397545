.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{

}

.menuItem {
  position: relative;
  background-color: white !important;
}

.menuItem::after{
  width:0%;
  height: 3px;
  content:' ';
  position: absolute;
  bottom: 0;
  background-color: rgb(248, 202, 65);
  transition: width 0.5s;
  left: 50%; /*Add this*/
  transform: translate(-50%, 0); /*Add this*/
}


.menuItem:hover::after {
  width:80%;
}


.cirlecontainer{
  position: relative;
}


.circle::after{
  position: absolute !important;
  width:50px;
  height:50px;
  border-radius: 100%;
  background-color: rgb(248, 202, 65);
  left:10px;
  top:10px;
  content: '';
  z-index: 0;
}

.kw{
  background-color: rgb(237,176,55);
}


.buy{
  color:black !important;
  background-color: rgb(248, 202, 65) !important;
}

.buy:hover{
  background-color: rgb(248, 202, 65) !important;
}

.Mui-focused fieldset{
  border-color: rgb(235, 188, 0) !important;
}

.triangle,.triangle2{
  position: relative;
  z-index: 0;
}

.triangle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(80% 0%, 0% 0%, 0% 100%);
  background-color:rgb(248, 202, 65);
  z-index: -1;
}

.triangle2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(20% 100%, 100% 0%, 100% 100%);
  background-color:rgb(237,176,55); 
  z-index: -1;
}

.drawerbtn:hover{
  background-color: rgb(168, 168, 168) !important;
  color: white !important;
}

.backtop{
  cursor: pointer;
}

